import { createGlobalStyle } from 'styled-components';
import theme from './theme';

export default createGlobalStyle`
  a {
    transition: all 320ms ease-in-out;
  }
  a:hover {
    text-decoration: none !important;
  }
  a:focus {
    border-color: ${theme.colors.primary} !important;
    box-shadow: none !important;
    outline: 0px !important;
  }
  body {
    background-color: ${theme.colors.white};
  }
  .container-fluid {
    padding: 0px !important;
  }
  .btn-close {
    color: ${theme.colors.white};
    font-family: ${theme.fonts.montserrat};
    font-weight: 600;
    padding: 7px 16px 5px 16px;
    background-color: ${theme.colors.primary};
    cursor: pointer;
  }
ol, p {
  margin-bottom: 0;
}
/* Top Page Scrolling Arrow */
#return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #ffd966;
  width: 40px;
  height: 40px;
  text-decoration: none;
  border-radius: 35px;
  display: none;
  transition: all 0.3s ease;
}

#return-to-top i {
  color: ${theme.colors.white};
  margin: 0;
  position: relative;
  left: 11px;
  top: 7px;
  font-size: 19px;
  transition: all 0.3s ease;
}
#return-to-top:hover {
  background: ${theme.colors.secondary};
}
#return-to-top:hover i {
  color: ${theme.colors.white};
  top: 5px;
}
`;
