import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { IMyWork } from '../interfaces';
import { useModalContext } from '@contexts';
import {
  ModalTitle,
  ModalBody,
  ModalFooter,
} from '@components/Homepage/ModalRoot/styles';
import Button from '@components/Shared/Button/Button';

const PortfolioModal: React.FC<IMyWork> = () => {
  const { hideModal } = useModalContext();
  return (
    <>
      <Modal.Header closeButton>
        <ModalTitle>BLA</ModalTitle>
      </Modal.Header>
      <ModalBody>DDDD</ModalBody>
      <ModalFooter>
        <Button text="Close" onClick={hideModal} faIconName={faTimes} />
      </ModalFooter>
    </>
  );
};
export default PortfolioModal;
