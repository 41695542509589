import ModalEnum from './modalEnum';
import PortfolioModal from '@components/Homepage/MyWork/Portfolio/PortfolioModal';

export interface IModalProps {
  hideModal: () => void;
}

type ModalMap = Record<ModalEnum, React.FC<any>>; // not sure how to flexibly type the props on generic React.FC

const ModalMap: ModalMap = {
  [ModalEnum.Portfolio]: PortfolioModal,
};

export default ModalMap;
