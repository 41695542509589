import { defineStyleConfig, Box, useStyleConfig } from '@chakra-ui/react';
import { colorsGrey } from '../colors';
import { montserrat } from '@src/theme/fonts';

export const NavigationItemTheme = defineStyleConfig({
  baseStyle: {
    fontFamily: montserrat.style.fontFamily,
    fontWeight: 'bold',
    fontSize: '12px',
    py: '5px',
    px: '20px',
    borderRadius: '15px',
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  variants: {
    normal: {
      color: colorsGrey['300'],
      bgColor: 'transparent',
    },
    selected: {
      color: 'white',
      bgColor: 'secondary',
    },
  },
  defaultProps: {
    variant: 'normal',
  },
});

type NavgationItemType = typeof NavigationItemTheme;
type NavigationItemProps = keyof NonNullable<NavgationItemType['variants']>;

export default function NavigationItem(props: {
  variant: NavigationItemProps;
  children?: React.ReactNode;
}) {
  const { variant, ...rest } = props;

  const styles = useStyleConfig('NavigationItemTheme', { variant });

  // Pass the computed styles into the `__css` prop
  return <Box __css={styles} {...rest} />;
}
