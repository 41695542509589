import React from 'react';
import Modal from 'react-bootstrap/Modal';
import modalMap from '../../../modals/modalMap';
import { useModalContext } from '@contexts';

const ModalRoot: React.FC = () => {
  const { modalType, props, isOpen, hideModal } = useModalContext();
  let Component: React.FC | null = null;
  if (modalType !== null) {
    Component = modalMap[modalType];
  }
  const handleClose = () => hideModal();

  return Component !== null ? (
    <Modal show={isOpen} onHide={handleClose} size="lg">
      <Component {...props} />
    </Modal>
  ) : null;
};

export default ModalRoot;
