export const colorsGrey = {
  50: '#f4f8fa',
  100: '#CED4DA',
  200: '#A8B3B9',
  300: '#868686',
  400: '#807F89',
  500: '#7D7D7D',
  600: '#6C757D',
  700: '#57565F',
};

export const colorsPink = {
  50: '#F9D5D3',
};

export const colorsGreen = {
  50: '#D7DBD1',
  100: '#cbe0db',
  200: '#BBC7BA',
  300: '#9cb0a1',
  400: '#99A89E',
};

export default {
  colors: {
    brand: {
      primary: '#eca4A6',
    },
    brandSecondary: {
      secondary: '#546959',
    },
    primary: '#eca4A6',
    secondary: '#546959',
    white: '#fff',
    black: '#000',
    grey: {
      ...colorsGrey,
    },
    pink: {
      ...colorsPink,
    },
    green: {
      ...colorsGreen,
    },
  },
};
