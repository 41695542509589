import { ChakraTheme, extendTheme } from '@chakra-ui/react';
import { ButtonTheme } from '../../components/Shared/ChakraButton/buttonTheme';
import { montserrat } from '../fonts';
import colors from './colors';
import { NavigationItemTheme } from './components/NavigationItem';
import fonts from './fonts';
import fontSizes from './fontSizes';
import fontWeights from './fontWeights';
import space from './space';

const theme: Partial<ChakraTheme> = {
  styles: {
    global: {
      body: {
        fontFamily: montserrat.style.fontFamily,
        background: '#f5f5f5',
      },
      '.yarl__thumbnails_thumbnail': {
        borderColor: 'primary',
      },
      '.yarl__button': {
        transition: 'all .3s ease-out',
        filter: 'drop-shadow(0 2px 2px #1a1a1a)',
      },
      '.yarl__button:hover': {
        color: 'primary',
      },
      '.yarl__button svg': {
        transition: 'all .3s ease-out',
        filter: 'drop-shadow(0 2px 2px #1a1a1a)',
      },
      '.yarl__button:hover svg': {
        transform: 'scale(1.1)',
      },
    },
  },
  ...fonts,
  ...colors,
  ...fontSizes,
  ...space,
  ...fontWeights,
  components: {
    ButtonTheme,
    NavigationItemTheme,
  },
};

export default extendTheme(theme);
