import { dancingScript, montserrat } from './fonts';

const theme = {
  fonts: {
    montserrat: montserrat.style.fontFamily,
    dancingScript: dancingScript.style.fontFamily,
  },
  colors: {
    primary: '#eca4A6',
    secondary: '#546959',
    white: '#fff',
    black: '#000',
    pink: {
      azalea: '#F9D5D3',
    },
    grey: {
      jumbo: '#807F89',
      catskillWhite: '#f4f8fa',
      light: '#868686',
      hitGrey: '#a8b3b9',
      scarpaFlow: '#57565f',
    },
    green: {
      pewter: '#99A89E',
      greenSpring: '#BBC7BA',
      celeste: '#D7DBD1',
      parisWhite: '#cbe0db',
    },
  },
  fontWeights: {
    light: 300,
    normal: 'normal',
    bold: 900,
  },
};

export default theme;
