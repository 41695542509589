import { css, FlattenSimpleInterpolation } from 'styled-components';

const breakpoints = {
  BreakpointSmallMobile: 480,
  BreakpointMediumMobile: 690,
  BreakpointMobile: 768,
  BreakpointTablet: 992,
  BreakpointDesktop: 1024,
  BreakpointLargeDesktop: 1280,
};

type IBreakpoints = typeof breakpoints;
export type IMediaQueries = {
  [P in keyof IBreakpoints]: (
    strs: TemplateStringsArray,
    ...exprs: any[]
  ) => FlattenSimpleInterpolation;
};

const mediaBreakpoints = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label as keyof IBreakpoints] = (
    strs: TemplateStringsArray,
    ...exprs: any[]
  ) => {
    const style = exprs.reduce(
      (result: TemplateStringsArray, expr: any, index: number) => {
        return `${result}${expr}${strs[index + 1]}`;
      },
      strs[0]
    );
    return css`
      @media (max-width: ${breakpoints[label as keyof IBreakpoints]}px) {
        ${css`
          ${style}
        `}
      }
    `;
  };
  return acc;
}, {} as IMediaQueries);

export default {
  ...mediaBreakpoints,
  breakpointValues: breakpoints,
};
