import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IButtonProps } from './interfaces';
import { ButtonWrapper, Text } from './styles';

const Button: React.FC<IButtonProps> = ({ text, onClick, faIconName }) => (
  <ButtonWrapper onClick={onClick}>
    {faIconName && <FontAwesomeIcon icon={faIconName} />}
    <Text isWithIcon={!!faIconName}>{text}</Text>
  </ButtonWrapper>
);

export default Button;
