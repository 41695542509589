import theme from './theme';
import transparent, { ITransparent } from './transparent';
import media, { IMediaQueries } from './breakpoints';
import bsMedia, { IBSMediaQueries } from './bootstrapBreakpoints';

export type ConfiguredTheme = typeof theme;

declare module 'styled-components' {
  export interface DefaultTheme
    extends ConfiguredTheme,
      IMediaQueries,
      IBSMediaQueries,
      ITransparent {}
}

export default {
  ...theme,
  ...media,
  ...bsMedia,
  transparent,
};
