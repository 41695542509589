/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import { NextDataHooksProvider } from 'next-data-hooks';
import { ChakraProvider } from '@chakra-ui/react';
import { CookiesProvider } from 'react-cookie';
// eslint-disable-next-line import/no-unresolved
import { Analytics } from '@vercel/analytics/react';
import theme from '../theme';
import './_app.scss';
import GlobalStyle from '../theme/global';
import 'bootstrap/dist/css/bootstrap.min.css';
import chakraTheme from '../theme/chakraTheme';
import { ModalProvider } from '@contexts';
import ModalRoot from '@components/Homepage/ModalRoot';
import GoogleAnalytics from '@components/GoogleAnalytics/GoogleAnalytics';

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => (
  <>
    <GoogleAnalytics />
    <ChakraProvider resetCSS={false} theme={chakraTheme}>
      {/* @ts-ignore */}
      <GlobalStyle />
      {/* @ts-ignore */}
      <ModalProvider>
        <NextDataHooksProvider {...pageProps}>
          {/* @ts-ignore */}
          <ThemeProvider theme={theme}>
            <CookiesProvider>
              <ModalRoot />
              <Component {...pageProps} />
              <Analytics />
            </CookiesProvider>
          </ThemeProvider>
        </NextDataHooksProvider>
      </ModalProvider>
    </ChakraProvider>
  </>
);

export default MyApp;
