export interface ITransparent {
  transparent: (color: string, percent: number) => string;
}

const transparent: ITransparent['transparent'] = (
  color: string,
  percent: number
): string => {
  const hex = (percent / 100).toString(16).slice(2, 4);
  return `${color}${hex.length === 1 ? `${hex}0` : hex}`;
};

export default transparent;
