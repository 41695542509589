import { ComponentSingleStyleConfig } from '@chakra-ui/react';
import { montserrat } from '@src/theme/fonts';

const sizes = {
  sm: {
    fontSize: '12px',
  },
  md: {
    fontSize: '16px',
  },
};

export type ButtonSizeType = keyof typeof sizes;

const variants = {
  primary: {
    color: 'white',
    bg: 'primary',
    borderColor: 'primary',
    _hover: {
      color: 'primary',
      bg: 'white',
    },
  },
  secondary: {
    color: 'white',
    bg: 'secondary',
    borderColor: 'secondary',
    _hover: {
      color: 'secondary',
      bg: 'white',
    },
  },
};
export type ButtonVariantType = keyof typeof variants;

export const ButtonTheme: ComponentSingleStyleConfig = {
  baseStyle: {
    fontFamily: montserrat.style.fontFamily,
    py: '8px',
    px: '16px',
    bg: 'primary',
    borderWidth: '1px',
    borderStyle: 'solid',
    cursor: 'pointer',
    _hover: {
      color: 'primary',
      bg: 'white',
    },
    transition: 'all 320ms ease-in-out',
  },
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
};
