import { css, FlattenSimpleInterpolation } from 'styled-components';

const breakpoints = {
  BSSmall: 575,
  BSMedium: 767,
  BSLarge: 991,
  BSExtraLarge: 1199,
};

type IBSBreakpoints = typeof breakpoints;
export type IBSMediaQueries = {
  [P in keyof IBSBreakpoints]: (
    strs: TemplateStringsArray,
    ...exprs: any[]
  ) => FlattenSimpleInterpolation;
};

const mediaBreakpoints = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label as keyof IBSBreakpoints] = (
    strs: TemplateStringsArray,
    ...exprs: any[]
  ) => {
    const style = exprs.reduce(
      (result: TemplateStringsArray, expr: any, index: number) => {
        return `${result}${expr}${strs[index + 1]}`;
      },
      strs[0]
    );
    return css`
      @media (max-width: ${breakpoints[label as keyof IBSBreakpoints]}px) {
        ${css`
          ${style}
        `}
      }
    `;
  };
  return acc;
}, {} as IBSMediaQueries);

export default {
  ...mediaBreakpoints,
  bsBreakpointValues: breakpoints,
};
