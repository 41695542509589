import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';

export const ModalTitle = styled(Modal.Title)`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 20px;
  font-size: 24px;
`;

export const ModalBody = styled(Modal.Body)`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-weight: 300;
  color: #868686;
  text-align: left;
`;

export const ModalImage = styled(Image)`
  margin: 0px auto;
  width: 100%;
`;

export const ModalField = styled.p`
  font-family: ${({ theme }) => theme.fonts.montserrat} !important;
  color: ${({ theme }) => theme.colors.primary} !important;
  margin-bottom: 0px;
  text-align: center;
`;

export const ModalFooter = styled(Modal.Footer)`
  justify-content: center !important;
  border-top: none !important;
  padding-top: 0px !important;
`;

export const ModalCloseBtn = styled.button`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-weight: 600;
  padding: 7px 16px 5px 16px;
  background-color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
`;
