import styled from 'styled-components';
import { ITextProps } from './interfaces';

export const ButtonWrapper = styled.a`
  color: ${({ theme }) => theme.colors.white} !important;
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-weight: 600;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary} !important;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.white} !important;
  }

  ${({ theme }) => theme.BreakpointSmallMobile`
    margin-bottom: 30px;
  `}
`;

export const Text = styled.span<ITextProps>`
  margin-left: ${({ isWithIcon }) => (isWithIcon ? '10px' : 0)};
`;
