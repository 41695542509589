/* eslint-disable no-shadow */
import React, { useState } from 'react';
import constate from 'constate';
import ModalEnum from '../../modals/modalEnum';
import { ModalContextValue } from './interfaces';

const useModal: ModalContextValue = () => {
  const [modalType, setModalType] = useState<ModalEnum | null>(null);
  const [props, setProps] = useState<Record<string, any>>({});
  const [modalStyle, setModalStyle] = useState<React.CSSProperties>({});
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const hideModal = () => {
    setIsOpen(false);
  };

  const showModal = (
    modalType: ModalEnum,
    props: Record<string, any> = {},
    modalStyle: React.CSSProperties = {}
  ) => {
    setIsOpen(true);
    setProps({ hideModal, ...props });
    setModalStyle(modalStyle);
    setModalType(modalType);
  };

  return {
    modalType,
    modalStyle,
    isOpen,
    props,
    showModal,
    hideModal,
  };
};
export const [ModalProvider, useModalContext] = constate(useModal);
